body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'montserrat', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  text, p{
    font-family: ''montserrat'', sans-serif;
    font-weight: 400;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: ''montserrat'', sans-serif;
    font-weight: 400;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 3rem;
  }